// Translated
// Migrated
<template>
  <button
    class="btn btn-rounded flex items-center"
    type="button"
    :class="active ? [(activeClass ?? 'active'), 'pl-5 pr-4'] : [inactiveClass, 'px-6']"
    @click="$emit('click')"
  >
    <fa
      v-if="icon"
      class="mr-2"
      :icon="icon"
    />
    <span>
      <slot />
    </span>
  </button>
</template>

<script>
export default defineNuxtComponent({
  props: {
    active: {
      type: Boolean,
      default: false,
    },

    activeClass: {
      type: String,
      default: null,
    },

    inactiveClass: {
      type: String,
      default: null,
    },

    icon: {
      type: Object,
      default: null,
    },
  },

  emits: ['click'],
})
</script>
